import { BaseTable, Block, Space, Typography } from '@codepoint-pt/xela';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { NewsletterSubscriber } from '../../models/NewsletterSubscriber';

interface NewsletterSubscribersProps {
  data?: NewsletterSubscriber[];
  loading?: boolean;
}

const NewsletterSubscribersTable = BaseTable<NewsletterSubscriber>();

const NewsletterSubscribers = ({
  data,
  loading
}: NewsletterSubscribersProps) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('EMAIL'),
      dataIndex: 'email'
    },
    {
      title: t('ACTIVE'),
      dataIndex: 'active',
      render: (value: string) =>
        value !== undefined ? t(value.toString().toUpperCase()) : ''
    },
    {
      title: t('DATE'),
      dataIndex: 'createdAt',
      render: (value: string) =>
        value ? dayjs(value).format('DD/MM/YYYY') : ''
    }
  ];

  return (
    <Block padding="30px" bg="white" borderRadius="16px">
      <Typography variant="subheadline">
        {t('LAST_10_NEWSLETTER_SUBSCRIBERS')}
      </Typography>
      <Space h={30} />
      <NewsletterSubscribersTable
        columns={columns}
        rows={data || []}
        loading={loading}
        translate={t}
      />
    </Block>
  );
};

export default NewsletterSubscribers;
