import {
  AspectRatios,
  Block,
  DateInput,
  Grid,
  ImageInput,
  LoadingOverlay,
  PageHeader,
  SelectInput,
  TextInput,
  toFormData
} from '@codepoint-pt/xela';
import yup from '@codepoint-pt/yup-validations';
import { useEffect, useState } from 'react';
import { Field, withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import useFetch from 'use-http';
import headerActions from '../../components/header/HeaderActions';
import FormPrompt from '../../components/prompt/FormPrompt';
import { useValidationSchema } from '../../hooks/use-validation/use-validation-schema';
import { User } from '../../models/User';
import { BasicForm } from '../../styles/BasicStyles';

const { Form } = withTypes<User>();

const ManageUserPage = () => {
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const { get, put, del } = useFetch('/users');

  useEffect(() => {
    const init = async () => {
      if (id) {
        const { data, success } = await get(`/${id}`);
        if (success) setInitialValues({ ...data, country: data.country?._id });
      }
      setLoading(false);
    };
    init();
  }, [id]);

  const onSubmit = async (values: User) => {
    const payload = toFormData(values);
    const { success } = await put(`/${id}`, payload);
    if (success) navigate('/users');
  };

  const onDelete = async () => {
    if (id) {
      setLoading(true);
      const { success } = await del(`/${id}`);
      if (success) navigate('/users');
      else setLoading(false);
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={useValidationSchema(
        yup.object({
          name: yup.string().required(),
          email: yup.string().email().required()
        })
      )}
    >
      {({ handleSubmit, submitting, pristine }) => (
        <BasicForm onSubmit={handleSubmit}>
          <LoadingOverlay visible={loading} />
          <FormPrompt
            when={!pristine && !submitting && !loading}
            message={t('PROMPT_MESSAGE')}
          />
          <PageHeader
            navigate={navigate}
            title={t(id ? 'EDIT_USER' : 'ADD_USER')}
            onBack={() => navigate('/users')}
            breadcrumbs={[
              { title: t('USERS'), href: '/users' },
              { title: t(id ? 'EDIT' : 'ADD') }
            ]}
            actions={headerActions({
              submitting,
              pristine,
              backLink: '/users',
              onDelete: id ? onDelete : undefined,
              deleteTitle: 'DELETE_USER',
              deleteMessage: 'DELETE_USER_MESSAGE'
            })}
          />
          <Block
            borderRadius="16px"
            bg="white"
            padding="50px 15px"
            margin="10px 0"
          >
            <Block margin="0 auto" style={{ maxWidth: '700px' }}>
              <Grid>
                <Grid.Col xs={6} offset={3}>
                  <Field name="photo">
                    {(props) => (
                      <ImageInput
                        {...props}
                        ratio={AspectRatios['1:1']}
                        label={t('PHOTO')}
                      />
                    )}
                  </Field>
                </Grid.Col>
                <Grid.Col xs={12}>
                  <Field name="email">
                    {(props) => (
                      <TextInput
                        {...props}
                        label={t('EMAIL')}
                        htmlType="email"
                        disabled
                      />
                    )}
                  </Field>
                </Grid.Col>
                <Grid.Col xs={12}>
                  <Field name="name">
                    {(props) => <TextInput {...props} label={t('NAME')} />}
                  </Field>
                </Grid.Col>
                <Grid.Col xs={12}>
                  <Field name="birth">
                    {(props) => (
                      <DateInput {...props} label={t('DATE_OF_BIRTH')} />
                    )}
                  </Field>
                </Grid.Col>
                <Grid.Col xs={12}>
                  <Field name="gender">
                    {(props) => (
                      <SelectInput
                        {...props}
                        label={t('GENDER')}
                        data={[
                          { _id: 'MALE', name: t('MALE') },
                          { _id: 'FEMALE', name: t('FEMALE') },
                          { _id: 'OTHER', name: t('OTHER') }
                        ]}
                      />
                    )}
                  </Field>
                </Grid.Col>
                <Grid.Col xs={12}>
                  <Field name="city">
                    {(props) => <TextInput {...props} label={t('CITY')} />}
                  </Field>
                </Grid.Col>
              </Grid>
            </Block>
          </Block>
        </BasicForm>
      )}
    </Form>
  );
};

export default ManageUserPage;
