import {
  Block,
  DateInput,
  Grid,
  LoadingOverlay,
  PageHeader,
  TextAreaInput,
  TextInput
} from '@codepoint-pt/xela';
import { useEffect, useState } from 'react';
import { Field, withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useFetch from 'use-http';
import headerActions from '../../components/header/HeaderActions';
import FileInput from '../../components/inputs/FileInput';
import FormPrompt from '../../components/prompt/FormPrompt';
import { CareerOpportunityApplication } from '../../models/CareerOpportunityApplication';
import { BasicForm } from '../../styles/BasicStyles';

const { Form } = withTypes<CareerOpportunityApplication>();

const ManageCareerOpportunitiesApplicationsPage = () => {
  const {
    t,
    i18n: { language }
  } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<
    CareerOpportunityApplication | undefined
  >(undefined);

  const { get } = useFetch('/career-opportunities-applications');

  useEffect(() => {
    const init = async () => {
      if (id) {
        const { data, success } = await get(`/${id}`);
        if (success) setInitialValues(data);
      }
      setLoading(false);
    };
    init();
  }, [id]);

  return (
    <Form onSubmit={() => void 0} initialValues={initialValues}>
      {({ handleSubmit, submitting, pristine, values }) => (
        <BasicForm onSubmit={handleSubmit}>
          <LoadingOverlay visible={loading} />
          <FormPrompt
            when={!pristine && !submitting && !loading}
            message={t('PROMPT_MESSAGE')}
          />
          <PageHeader
            navigate={navigate}
            title={t('CAREER_APPLICATION')}
            onBack={() => navigate('/career-opportunities-applications')}
            breadcrumbs={[
              {
                title: t('CAREER_APPLICATIONS'),
                href: '/career-opportunities-applications'
              },
              { title: t('EDIT') }
            ]}
            actions={headerActions({
              submitting,
              pristine,
              backLink: '/career-opportunities-applications'
            })}
          />
          <Block
            borderRadius="16px"
            bg="white"
            padding="50px 15px"
            margin="10px 0"
          >
            <Block margin="0 auto" style={{ maxWidth: '700px' }}>
              <Grid>
                <Grid.Col xs={12}>
                  <Field name="career_opportunity_reference._id">
                    {(props) => (
                      <TextInput {...props} label={t('REFERENCE')} disabled />
                    )}
                  </Field>
                </Grid.Col>

                {values?.career_opportunity_reference ? (
                  <Grid.Col xs={12}>
                    <Field
                      name={`career_opportunity_reference.position.${language}`}
                    >
                      {(props) => (
                        <TextInput {...props} label={t('POSITION')} disabled />
                      )}
                    </Field>
                  </Grid.Col>
                ) : (
                  <Grid.Col xs={12}>
                    <Field name="role">
                      {(props) => (
                        <TextInput {...props} label={t('POSITION')} disabled />
                      )}
                    </Field>
                  </Grid.Col>
                )}

                <Grid.Col xs={12}>
                  <Field name="name">
                    {(props) => (
                      <TextInput {...props} label={t('NAME')} disabled />
                    )}
                  </Field>
                </Grid.Col>

                <Grid.Col xs={12}>
                  <Field name="email">
                    {(props) => (
                      <TextInput {...props} label={t('EMAIL')} disabled />
                    )}
                  </Field>
                </Grid.Col>

                <Grid.Col xs={12}>
                  <Field name="createdAt">
                    {(props) => (
                      <DateInput {...props} label={t('DATE')} disabled />
                    )}
                  </Field>
                </Grid.Col>

                <Grid.Col xs={12}>
                  <Field name="message">
                    {(props) => (
                      <TextAreaInput {...props} label={t('MESSAGE')} disabled />
                    )}
                  </Field>
                </Grid.Col>

                {/* TODO test this */}
                <Grid.Col xs={12}>
                  <Field name="file">
                    {(props) => (
                      <FileInput
                        {...props}
                        label={t('file')}
                        disabled
                        unavailable
                      />
                    )}
                  </Field>
                </Grid.Col>
              </Grid>
            </Block>
          </Block>
        </BasicForm>
      )}
    </Form>
  );
};

export default ManageCareerOpportunitiesApplicationsPage;
