import {
  Block,
  Grid,
  LoadingOverlay,
  NumberInput,
  PageHeader,
  SelectInput,
  TextInput,
  toFormData
} from '@codepoint-pt/xela';
import yup from '@codepoint-pt/yup-validations';
import { useEffect, useState } from 'react';
import { Field, withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useFetch from 'use-http';
import headerActions from '../../components/header/HeaderActions';
import IntlFileInput from '../../components/inputs/IntlFileInput';
import TranslatableFields from '../../components/inputs/TranslatableFields';
import FormPrompt from '../../components/prompt/FormPrompt';
import useIntlValidation from '../../hooks/use-validation/use-inlt-validation';
import useSectionValidation from '../../hooks/use-validation/use-section-validation';
import { useValidationSchema } from '../../hooks/use-validation/use-validation-schema';
import {
  AgsCommunication,
  FileDocumentTypeLEnum
} from '../../models/AgsCommunication';
import { BasicForm } from '../../styles/BasicStyles';

const { Form } = withTypes<AgsCommunication>();

const ManageAgsCommunicationPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const intlObject = useIntlValidation();

  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({});

  const { get, post, put, del } = useFetch('/ags-communications');

  useEffect(() => {
    const init = async () => {
      if (id) {
        const { data, success } = await get(`/${id}`);
        if (success) setInitialValues(data);
      }
      setLoading(false);
    };
    init();
  }, [id]);

  const onSubmit = async (values: AgsCommunication) => {
    let success = false;
    const payload = toFormData(values);

    if (id) {
      const result = await put(`/${values._id}`, payload);
      success = result.success;
    } else {
      const result = await post(payload);
      success = result.success;
    }

    if (success) navigate('/ags-communications');
  };

  const onDelete = async () => {
    if (id) {
      setLoading(true);
      const { success } = await del(`/${id}`);
      if (success) navigate('/ags-communications');
      else setLoading(false);
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={useValidationSchema(
        yup.object({
          title: intlObject({ required: true }),
          type: yup
            .string()
            .oneOf([
              FileDocumentTypeLEnum.CORPORATE_GOVERNANCE,
              FileDocumentTypeLEnum.POLICIES,
              FileDocumentTypeLEnum.COMMUNICATION
            ])
            .required(),
          order: yup.number().min(1).required()
        })
      )}
    >
      {({ handleSubmit, submitting, pristine, errors, submitFailed }) => (
        <BasicForm onSubmit={handleSubmit}>
          <LoadingOverlay visible={loading} />
          <FormPrompt
            when={!pristine && !submitting && !loading}
            message={t('PROMPT_MESSAGE')}
          />
          <PageHeader
            navigate={navigate}
            title={t(
              id ? 'EDIT_AGS_COMMUNICATION_FILE' : 'ADD_AGS_COMMUNICATION_FILE'
            )}
            onBack={() => navigate('/ags-communications')}
            breadcrumbs={[
              { title: t('AGS_COMMUNICATION'), href: '/ags-communications' },
              { title: t(id ? 'EDIT' : 'ADD') }
            ]}
            actions={headerActions({
              submitting,
              pristine,
              backLink: '/ags-communications',
              onDelete: id ? onDelete : undefined
            })}
          />
          <Block
            borderRadius="16px"
            bg="white"
            padding="50px 15px"
            margin="10px 0"
          >
            <Block margin="0 auto" style={{ maxWidth: '700px' }}>
              <Grid>
                <Grid.Col xs={12}>
                  <Field name="sectionId">
                    {(props) => (
                      <TextInput
                        {...props}
                        label={t('SECTION_ID')}
                        type="email"
                      />
                    )}
                  </Field>
                </Grid.Col>

                <Grid.Col xs={12}>
                  <TranslatableFields
                    subValidate={(fields) =>
                      useSectionValidation(errors, fields, submitFailed)
                    }
                    fields={[
                      {
                        label: 'TITLE',
                        name: 'title',
                        type: 'text',
                        required: true
                      }
                    ]}
                  />
                </Grid.Col>

                {/* TODO file input */}
                {/* TODO add asterisk required */}
                <Grid.Col xs={12}>
                  <Field name="file">
                    {(props) => <IntlFileInput {...props} label={t('FILE')} />}
                  </Field>
                </Grid.Col>

                <Grid.Col xs={12}>
                  <Field name="type">
                    {(props) => (
                      <SelectInput
                        {...props}
                        label={t('TYPE')}
                        placeholder={t('INPUT_TYPE_PLACEHOLDER')}
                        data={[
                          {
                            _id: FileDocumentTypeLEnum.CORPORATE_GOVERNANCE,
                            name: t(FileDocumentTypeLEnum.CORPORATE_GOVERNANCE)
                          },
                          {
                            _id: FileDocumentTypeLEnum.POLICIES,
                            name: t(FileDocumentTypeLEnum.POLICIES)
                          },
                          {
                            _id: FileDocumentTypeLEnum.COMMUNICATION,
                            name: t(FileDocumentTypeLEnum.COMMUNICATION)
                          }
                        ]}
                        required
                      />
                    )}
                  </Field>
                </Grid.Col>

                <Grid.Col xs={12}>
                  <Field name="order">
                    {(props) => (
                      <NumberInput
                        {...props}
                        label={t('ORDER')}
                        placeholder={t('INPUT_ORDER_PLACEHOLDER')}
                        min={1}
                        required
                      />
                    )}
                  </Field>
                </Grid.Col>
              </Grid>
            </Block>
          </Block>
        </BasicForm>
      )}
    </Form>
  );
};

export default ManageAgsCommunicationPage;
