import { BaseTable, Block, Space, Typography } from '@codepoint-pt/xela';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ContactRequest } from '../../models/ContactRequest';

interface ContactRequestsProps {
  data?: ContactRequest[];
  loading?: boolean;
}

const ContactRequestsTable = BaseTable<ContactRequest>();

const ContactRequests = ({ data, loading }: ContactRequestsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = [
    {
      title: t('NAME'),
      dataIndex: 'name'
    },
    {
      title: t('EMAIL'),
      dataIndex: 'email'
    },
    {
      title: t('DATE'),
      dataIndex: 'createdAt',
      render: (value: string) =>
        value ? dayjs(value).format('DD/MM/YYYY') : ''
    }
  ];

  return (
    <Block padding="30px" bg="white" borderRadius="16px">
      <Typography variant="subheadline">
        {t('LAST_10_CONTACT_REQUESTS')}
      </Typography>
      <Space h={30} />
      <ContactRequestsTable
        columns={columns}
        rows={data || []}
        onRowClick={(row) => navigate(`/contact-requests/${row._id}`)}
        loading={loading}
        translate={t}
      />
    </Block>
  );
};

export default ContactRequests;
