import { DefaultListPage, Grid, Icon, SearchInput } from '@codepoint-pt/xela';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useFetch from 'use-http';
import { NewsletterSubscriber } from '../../models/NewsletterSubscriber';

const NewsletterListPage = DefaultListPage<NewsletterSubscriber>();

const NewsletterPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loadingExport, setLoadingExport] = useState<boolean>(false);

  const { get } = useFetch('/newsletter-subscribers');

  const columns = [
    {
      title: t('EMAIL'),
      dataIndex: 'email',
      sortable: true
    },
    {
      title: t('DATE'),
      dataIndex: 'createdAt',
      sortable: true,
      render: (value: string) =>
        value ? dayjs(value).format('DD/MM/YYYY') : ''
    },
    {
      title: t('ACTIVE'),
      dataIndex: 'active',
      sortable: true,
      render: (value: string) =>
        value !== undefined ? t(value.toString().toUpperCase()) : ''
    }
  ];

  const { post: getNewsletterSubscribers } = useFetch(
    '/newsletter-subscribers/export'
  );

  const exportNewsletterSubscribers = async () => {
    try {
      setLoadingExport(true);

      const result = await getNewsletterSubscribers();

      if (result) {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([result]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'newsletter-subscribers.csv');

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode?.removeChild(link);
      }

      setLoadingExport(false);
    } catch (error) {
      setLoadingExport(false);
    }
  };

  return (
    <NewsletterListPage
      translate={t}
      request={{
        get,
        searchFields: ['email']
      }}
      storage="NEWSLETTER_FILTERS"
      header={{
        navigate,
        title: t('NEWSLETTER'),
        actions: [
          {
            name: t('EXPORT_CSV'),
            icon: <Icon icon="files_csv" color="white" />,
            onClick: exportNewsletterSubscribers,
            loading: loadingExport
          }
        ],
        breadcrumbs: [{ title: t('NEWSLETTER'), href: '/newsletter' }]
      }}
      filters={({ filters, handleChange }) => (
        <Grid gutter={24}>
          <Grid.Col xs={4}>
            <SearchInput
              input={{
                value: filters.search,
                onChange: (v: string | null) => handleChange('search', v, 1000)
              }}
              onSearch={(v) => handleChange('search', v)}
            />
          </Grid.Col>
        </Grid>
      )}
      table={{
        columns,
        onRowClick: (row) => navigate(`/newsletter/${row._id}`)
      }}
    />
  );
};

export default NewsletterPage;
