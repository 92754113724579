import { Metatags } from '../../models/Metatags';
import { useTranslation } from 'react-i18next';
import { DefaultListPage, Grid, SearchInput } from '@codepoint-pt/xela';
import { useNavigate } from 'react-router-dom';
import useFetch from 'use-http';

const AdminListPage = DefaultListPage<Metatags>();

const MetatagsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = [
    { title: t('NAME'), dataIndex: 'name', sortable: true },
    { title: t('TAG'), dataIndex: 'tag', sortable: true }
  ];

  const { get } = useFetch('/metatags');

  return (
    <AdminListPage
      translate={t}
      request={{
        get,
        searchFields: ['name', 'tag']
      }}
      storage="METATAGS_FILTERS"
      header={{
        navigate,
        title: t('METATAGS'),
        actions: [],
        breadcrumbs: [{ title: t('METATAGS'), href: '/metatags' }]
      }}
      filters={({ filters, handleChange }) => (
        <Grid gutter={24}>
          <Grid.Col xs={4}>
            <SearchInput
              input={{
                value: filters.search,
                onChange: (v: string | null) => handleChange('search', v, 1000)
              }}
              onSearch={(v) => handleChange('search', v)}
            />
          </Grid.Col>
        </Grid>
      )}
      table={{
        columns,
        onRowClick: (row) => navigate(`/metatags/${row._id}`)
      }}
    />
  );
};

export default MetatagsPage;
