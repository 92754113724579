import { InputWrapper, TextInput, XelaColor } from '@codepoint-pt/xela';
import { useContext, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { LanguageContext } from '../../Context';
import { AnyObject, GenericInput } from '../../models/Generic';

const InputLabel = styled.div<{ $error: boolean; $focus: boolean }>`
  width: 48px;
  height: 100%;
  border-right: 1px solid
    ${({ $error, $focus }) =>
      $focus ? XelaColor.Indigo6 : $error ? XelaColor.Red5 : XelaColor.Gray10};
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 26px;
  }
`;

interface IntlTitleProps extends Omit<FieldRenderProps<AnyObject>, 'input'> {
  afterChange?: (value: AnyObject) => void;
  input: GenericInput<AnyObject>;
  placeholder?: string;
  maxChar?: number;
  required?: boolean;
}

const IntlTitleInput: React.FC<IntlTitleProps> = ({
  input,
  meta,
  label,
  placeholder,
  afterChange,
  maxChar,
  required
}) => {
  const [focus, setFocus] = useState<string>();
  const { t } = useTranslation();
  const languages = useContext(LanguageContext);

  const value = languages.reduce((result: AnyObject, lang) => {
    if (input.value && input.value[lang.code]) {
      result[lang.code] = input.value[lang.code];
    } else result[lang.code] = '';
    return result;
  }, {});

  const showError = meta.invalid && meta.submitFailed;

  const handleInputChange = (text: string | null, code: string) => {
    const newValue = { ...value };
    newValue[code] = text;
    input.onChange(newValue);
    if (afterChange && typeof afterChange === 'function') {
      afterChange(newValue);
    }
  };

  return (
    <InputWrapper label={label} withAsterisk={required}>
      {languages.map((lang) => {
        const error =
          showError &&
          meta.error &&
          (typeof meta.error === 'string' || !!meta.error[lang.code]);

        return (
          <TextInput
            input={{
              value: value[lang.code],
              onChange: (text: string | null) =>
                handleInputChange(text, lang.code)
            }}
            key={lang.code}
            placeholder={t(placeholder || lang.tag, { lang: t(lang.tag) })}
            meta={{ ...meta, invalid: error }}
            onFocus={() => setFocus(lang.code)}
            onBlur={() => setFocus(undefined)}
            icon={
              <InputLabel $error={error} $focus={focus === lang.code}>
                <img src={lang.icon} />
              </InputLabel>
            }
            styles={{
              wrapper: { marginBottom: '3px' },
              icon: { width: '48px' },
              withIcon: { paddingLeft: '56px' }
            }}
            maxChar={maxChar}
          />
        );
      })}
    </InputWrapper>
  );
};

export default IntlTitleInput;
