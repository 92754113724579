import {
  Block,
  DateInput,
  Grid,
  LoadingOverlay,
  PageHeader,
  TextAreaInput,
  TextInput,
  toFormData
} from '@codepoint-pt/xela';
import { useEffect, useState } from 'react';
import { Field, withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useFetch from 'use-http';
import headerActions from '../../components/header/HeaderActions';
import FormPrompt from '../../components/prompt/FormPrompt';
import { ContactRequest } from '../../models/ContactRequest';
import { BasicForm } from '../../styles/BasicStyles';

const { Form } = withTypes<ContactRequest>();

const ManageContactRequestsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({});

  const { get, post, put } = useFetch('/contact-requests');

  useEffect(() => {
    const init = async () => {
      if (id) {
        const { data, success } = await get(`/${id}`);
        if (success) setInitialValues(data);
      }
      setLoading(false);
    };
    init();
  }, [id]);

  const onSubmit = async (values: ContactRequest) => {
    let success = false;
    const payload = toFormData(values);

    if (id) {
      const result = await put(`/${values._id}`, payload);
      success = result.success;
    } else {
      const result = await post(payload);
      success = result.success;
    }

    if (success) navigate('/programs');
  };

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, submitting, pristine }) => (
        <BasicForm onSubmit={handleSubmit}>
          <LoadingOverlay visible={loading} />
          <FormPrompt
            when={!pristine && !submitting && !loading}
            message={t('PROMPT_MESSAGE')}
          />
          <PageHeader
            navigate={navigate}
            title={t('CONTACT_REQUEST')}
            onBack={() => navigate('/contact-requests')}
            breadcrumbs={[
              { title: t('CONTACT_REQUESTS'), href: '/contact-requests' },
              { title: t('CONTACT_REQUEST') }
            ]}
            actions={headerActions({
              submitting,
              pristine,
              onSave: false,
              backLink: '/contact-requests'
            })}
          />
          <Block
            borderRadius="16px"
            bg="white"
            padding="50px 15px"
            margin="10px 0"
          >
            <Block margin="0 auto" style={{ maxWidth: '700px' }}>
              <Grid>
                <Grid.Col xs={12}>
                  <Field name="name">
                    {(props) => (
                      <TextInput {...props} label={t('NAME')} disabled />
                    )}
                  </Field>
                </Grid.Col>

                <Grid.Col xs={12}>
                  <Field name="email">
                    {(props) => (
                      <TextInput
                        {...props}
                        label={t('EMAIL')}
                        htmlType="email"
                        disabled
                      />
                    )}
                  </Field>
                </Grid.Col>

                <Grid.Col xs={12}>
                  <Field name="createdAt">
                    {(props) => (
                      <DateInput {...props} label={t('DATE')} disabled />
                    )}
                  </Field>
                </Grid.Col>

                <Grid.Col xs={12}>
                  <Field name="subject">
                    {(props) => (
                      <TextInput {...props} label={t('SUBJECT')} disabled />
                    )}
                  </Field>
                </Grid.Col>

                <Grid.Col xs={12}>
                  <Field name="message">
                    {(props) => (
                      <TextAreaInput {...props} label={t('MESSAGE')} disabled />
                    )}
                  </Field>
                </Grid.Col>
              </Grid>
            </Block>
          </Block>
        </BasicForm>
      )}
    </Form>
  );
};

export default ManageContactRequestsPage;
