import { DefaultListPage, Grid, SearchInput } from '@codepoint-pt/xela';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useFetch from 'use-http';
import { Configuration } from '../../models/Configuration';

const ConfigurationsListPage = DefaultListPage<Configuration>();

const ConfigurationsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = [
    {
      title: t('NAME'),
      dataIndex: 'name',
      sortable: true
    },
    {
      title: t('VALUE'),
      dataIndex: 'value',
      sortable: true
    }
  ];

  const { get } = useFetch('/configurations');

  return (
    <ConfigurationsListPage
      translate={t}
      request={{
        get,
        searchFields: ['title']
      }}
      storage="CONFIGURATIONS_FILTERS"
      header={{
        navigate,
        title: t('CONFIGURATIONS'),
        breadcrumbs: [{ title: t('CONFIGURATIONS'), href: '/configurations' }]
      }}
      filters={({ filters, handleChange }) => (
        <Grid gutter={24}>
          <Grid.Col xs={4}>
            <SearchInput
              input={{
                value: filters.search,
                onChange: (v: string | null) => handleChange('search', v, 1000)
              }}
              onSearch={(v) => handleChange('search', v)}
            />
          </Grid.Col>
        </Grid>
      )}
      table={{
        columns,
        onRowClick: (row) => navigate(`/configurations/${row._id}`)
      }}
    />
  );
};

export default ConfigurationsPage;
