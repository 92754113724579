import { InputWrapper, XelaColor } from '@codepoint-pt/xela';
import { useContext } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { LanguageContext } from '../../Context';
import { AnyObject, GenericInput } from '../../models/Generic';
import FileInput from './FileInput';

const InputLabel = styled.div<{ $error: boolean }>`
  width: 48px;
  height: 100%;
  border-right: 1px solid
    ${({ $error }) => ($error ? XelaColor.Red5 : XelaColor.Gray10)};
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 26px;
  }
`;

interface IntlFileProps extends Omit<FieldRenderProps<AnyObject>, 'input'> {
  input: GenericInput<AnyObject>;
  placeholder?: string;
}

const IntlFileInput: React.FC<IntlFileProps> = ({
  input,
  meta,
  label,
  placeholder
}) => {
  const { t } = useTranslation();
  const languages = useContext(LanguageContext);

  const value = languages.reduce((result: AnyObject, lang) => {
    if (input.value && input.value[lang.code]) {
      result[lang.code] = input.value[lang.code];
    } else result[lang.code] = undefined;
    return result;
  }, {});

  const showError = meta.invalid && meta.submitFailed;

  const handleInputChange = (file: AnyObject, code: string) => {
    const newValue = { ...value };
    newValue[code] = file;
    input.onChange(newValue);
  };

  return (
    <InputWrapper label={label}>
      {languages.map((lang) => {
        const error =
          showError &&
          meta.error &&
          (typeof meta.error === 'string' || !!meta.error[lang.code]);

        return (
          <FileInput
            key={lang.code}
            input={{
              value: value[lang.code],
              onChange: (file: AnyObject) => handleInputChange(file, lang.code)
            }}
            placeholder={t(placeholder || lang.tag, { lang: t(lang.tag) })}
            meta={{ ...meta, invalid: error }}
            icon={
              <InputLabel $error={error}>
                <img src={lang.icon} />
              </InputLabel>
            }
          />
        );
      })}
    </InputWrapper>
  );
};

export default IntlFileInput;
