import { DefaultListPage, Grid, SearchInput } from '@codepoint-pt/xela';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useFetch from 'use-http';
import ActiveTranslation from '../../components/languages/ActiveTranslation';
import { Company } from '../../models/Company';
import { AnyObject } from '../../models/Generic';

const CompaniesListPage = DefaultListPage<Company>();

const CompaniesPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = [
    {
      title: t('TITLE'),
      dataIndex: 'title',
      sortable: true,
      render: (value: AnyObject) =>
        value ? <ActiveTranslation value={value} /> : t('NO_RECORD')
    }
  ];

  const { get } = useFetch('/companies');

  return (
    <CompaniesListPage
      translate={t}
      request={{
        get,
        searchFields: ['title']
      }}
      storage="COMPANIES_FILTERS"
      header={{
        navigate,
        title: t('COMPANIES'),
        breadcrumbs: [{ title: t('COMPANIES'), href: '/companies' }]
      }}
      filters={({ filters, handleChange }) => (
        <Grid gutter={24}>
          <Grid.Col xs={4}>
            <SearchInput
              input={{
                value: filters.search,
                onChange: (v: string | null) => handleChange('search', v, 1000)
              }}
              onSearch={(v) => handleChange('search', v)}
            />
          </Grid.Col>
        </Grid>
      )}
      table={{
        columns,
        onRowClick: (row) => navigate(`/companies/${row._id}`)
      }}
    />
  );
};

export default CompaniesPage;
