const Platforms = [
  { _id: 'COMMON', name: 'Common', tag: 'COMMON' },
  { _id: 'BO', name: 'Backoffice', tag: 'BACKOFFICE' },
  { _id: 'SERVER', name: 'Server', tag: 'SERVER' },
  { _id: 'FO', name: 'Web', tag: 'WEB' }
];

export const GetPlatformTag = (id: string) => {
  const found = Platforms.find((p) => p._id === id);
  return found?.tag || id;
};

export default Platforms;
