import { DefaultListPage, Grid, SearchInput } from '@codepoint-pt/xela';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useFetch from 'use-http';
import ActiveTranslation from '../../components/languages/ActiveTranslation';
import { Company } from '../../models/Company';
import { AnyObject } from '../../models/Generic';

const CompaniesListPage = DefaultListPage<Company>();

const PresidentMessagePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = [
    {
      title: t('HIGHLIGHT'),
      dataIndex: 'highlight',
      sortable: true,
      render: (value: AnyObject) =>
        value ? <ActiveTranslation value={value} /> : t('NO_RECORD')
    },
    {
      title: t('LEAD'),
      dataIndex: 'lead',
      sortable: true,
      render: (value: AnyObject) =>
        value ? <ActiveTranslation value={value} /> : t('NO_RECORD')
    }
  ];

  const { get } = useFetch('/president-message');

  return (
    <CompaniesListPage
      translate={t}
      request={{
        get,
        searchFields: ['highlight', 'lead']
      }}
      storage="PRESIDENT_MESSAGE_FILTERS"
      header={{
        navigate,
        title: t('PRESIDENT_MESSAGE'),
        breadcrumbs: [
          { title: t('PRESIDENT_MESSAGE'), href: '/president-message' }
        ]
      }}
      filters={({ filters, handleChange }) => (
        <Grid gutter={24}>
          <Grid.Col xs={4}>
            <SearchInput
              input={{
                value: filters.search,
                onChange: (v: string | null) => handleChange('search', v, 1000)
              }}
              onSearch={(v) => handleChange('search', v)}
            />
          </Grid.Col>
        </Grid>
      )}
      table={{
        columns,
        onRowClick: (row) => navigate(`/president-message/${row._id}`)
      }}
    />
  );
};

export default PresidentMessagePage;
