import { DefaultListPage, Grid, Icon, SearchInput } from '@codepoint-pt/xela';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useFetch from 'use-http';
import ActiveTranslation from '../../components/languages/ActiveTranslation';
import { AgsCommunication } from '../../models/AgsCommunication';
import { AnyObject } from '../../models/Generic';

const AgsCommunicationListPage = DefaultListPage<AgsCommunication>();

const AgsCommunicationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = [
    {
      title: t('TITLE'),
      dataIndex: 'title',
      sortable: true,
      render: (value: AnyObject) =>
        value ? <ActiveTranslation value={value} /> : t('NO_RECORD')
    },
    {
      title: t('TYPE'),
      dataIndex: 'type',
      sortable: true,
      render: (value: AnyObject) => (value ? t(value) : t('NO_RECORD'))
    },
    {
      title: t('ORDER'),
      dataIndex: 'order',
      sortable: true
    }
  ];

  const { get } = useFetch('/ags-communications');

  return (
    <AgsCommunicationListPage
      translate={t}
      request={{
        get,
        searchFields: ['title', 'type']
      }}
      storage="AGS_COMMUNICATION_FILTERS"
      header={{
        navigate,
        title: t('AGS_COMMUNICATION'),
        actions: [
          {
            name: t('ADD_NEW'),
            icon: <Icon icon="basics_add" color="white" />,
            onClick: () => navigate('/ags-communications/new')
          }
        ],
        breadcrumbs: [
          { title: t('AGS_COMMUNICATION'), href: '/ags-communications' }
        ]
      }}
      filters={({ filters, handleChange }) => (
        <Grid gutter={24}>
          <Grid.Col xs={4}>
            <SearchInput
              input={{
                value: filters.search,
                onChange: (v: string | null) => handleChange('search', v, 1000)
              }}
              onSearch={(v) => handleChange('search', v)}
            />
          </Grid.Col>
        </Grid>
      )}
      table={{
        columns,
        onRowClick: (row) => navigate(`/ags-communications/${row._id}`)
      }}
    />
  );
};

export default AgsCommunicationPage;
