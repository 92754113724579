import {
  AnyObject,
  DefaultListPage,
  Grid,
  Icon,
  SearchInput
} from '@codepoint-pt/xela';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useFetch from 'use-http';
import ActiveTranslation from '../../components/languages/ActiveTranslation';
import { PrivacyPolicies } from '../../models/PrivacyPolicies';

const PrivacyPoliciesListPage = DefaultListPage<PrivacyPolicies>();

const PrivacyPoliciesPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = [
    {
      title: t('TITLE'),
      dataIndex: 'title',
      sortable: true,
      render: (value: AnyObject) => <ActiveTranslation value={value} />
    },
    { title: t('Tag'), dataIndex: 'tag', sortable: true },
    { title: t('ORDER'), dataIndex: 'order', sortable: true }
  ];

  const { get } = useFetch('/privacy-policies');

  return (
    <PrivacyPoliciesListPage
      translate={t}
      request={{
        get,
        searchFields: ['title|intl', 'tag']
      }}
      storage="PRIVACY_POLICIES_FILTERS"
      header={{
        navigate,
        title: t('PRIVACY_POLICIES'),
        actions: [
          {
            name: t('ADD_NEW'),
            icon: <Icon icon="basics_add" color="white" />,
            onClick: () => navigate('/privacy-policies/new')
          }
        ],
        breadcrumbs: [
          { title: t('PRIVACY_POLICIES'), href: '/privacy-policies' }
        ]
      }}
      filters={({ filters, handleChange }) => (
        <Grid gutter={24}>
          <Grid.Col xs={4}>
            <SearchInput
              input={{
                value: filters.search,
                onChange: (v: string | null) => handleChange('search', v, 1000)
              }}
              onSearch={(v) => handleChange('search', v)}
            />
          </Grid.Col>
        </Grid>
      )}
      table={{
        columns,
        onRowClick: (row) => navigate(`/privacy-policies/${row._id}`)
      }}
    />
  );
};

export default PrivacyPoliciesPage;
