import { AnyObject } from './Generic';

export interface CareerOpportunity {
  readonly _id: string;
  reference: string;
  position: AnyObject;
  description: AnyObject;
  status: CareerOpportunityStatusEnum;
}

export enum CareerOpportunityStatusEnum {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  DELETED = 'DELETED'
}
