import AgsCommunicationPage from '../../pages/agsCommunication/AgsCommunicationPage';
import ManageAgsCommunicationPage from '../../pages/agsCommunication/ManageAgsCommunicationPage';
import BannerOptionsPage from '../../pages/bannerOptions/BannerOptionsPage';
import ManageBannerOptionsPage from '../../pages/bannerOptions/ManageBannerOptionsPage';
import BusinessAreasPage from '../../pages/businessAreas/BusinessAreasPage';
import ManageBusinessAreasPage from '../../pages/businessAreas/ManageBusinessAreasPage';
import BusinessSubAreasPage from '../../pages/businessSubAreas/BusinessSubAreasPage';
import ManageBusinessSubAreasPage from '../../pages/businessSubAreas/ManageBusinessSubAreasPage';
import CareerOpportunitiesPage from '../../pages/careerOpportunities/CareerOpportunitiesPage';
import ManageCareerOpportunitiesPage from '../../pages/careerOpportunities/ManageCareerOpportunitiesPage';
import CareerOpportunitiesApplicationsPage from '../../pages/careerOpportunitiesApplications/CareerOpportunitiesApplicationsPage';
import ManageCareerOpportunitiesApplicationsPage from '../../pages/careerOpportunitiesApplications/ManageCareerOpportunitiesApplicationsPage';
import CompaniesPage from '../../pages/companies/CompaniesPage';
import ManageCompaniesPage from '../../pages/companies/ManageCompaniesPage';
import ConfigurationsPage from '../../pages/configurations/ConfigurationsPage';
import ManageConfigurationsPage from '../../pages/configurations/ManageConfigurationsPage';
import ContactRequestsPage from '../../pages/contactRequests/ContactRequestsPage';
import ManageContactRequestsPage from '../../pages/contactRequests/ManageContactRequestsPage';
import ManageNewsletterPage from '../../pages/newsletter/ManageNewsletterPage';
import NewsletterPage from '../../pages/newsletter/NewsletterPage';
import ManagePortfolioProjectsPage from '../../pages/portfolioProjects/ManagePortfolioProjectsPage';
import PortfolioProjectsPage from '../../pages/portfolioProjects/PortfolioProjectsPage';
import ManagePresidentMessagePage from '../../pages/presidentMessage/ManagePresidentMessagePage';
import PresidentMessagePage from '../../pages/presidentMessage/PresidentMessagePage';
import ManagePrivacyPoliciesPage from '../../pages/privacyPolicies/ManagePrivacyPoliciesPage';
import PrivacyPoliciesPage from '../../pages/privacyPolicies/PrivacyPolicyPage';
import ManageTeamMessagePage from '../../pages/teamMessage/ManageTeamMessagePage';
import TeamMessagePage from '../../pages/teamMessage/TeamMessagePage';

const EntityRoutes = [
  {
    path: '/contact-requests',
    component: <ContactRequestsPage />,
    isAuthenticated: true
  },
  {
    path: '/contact-requests/new',
    component: <ManageContactRequestsPage />,
    isAuthenticated: true
  },
  {
    path: '/contact-requests/:id',
    component: <ManageContactRequestsPage />,
    isAuthenticated: true
  },
  {
    path: '/banner-options',
    component: <BannerOptionsPage />,
    isAuthenticated: true
  },
  {
    path: '/banner-options/new',
    component: <ManageBannerOptionsPage />,
    isAuthenticated: true
  },
  {
    path: '/banner-options/:id',
    component: <ManageBannerOptionsPage />,
    isAuthenticated: true
  },
  {
    path: '/president-message',
    component: <PresidentMessagePage />,
    isAuthenticated: true
  },
  {
    path: '/president-message/:id',
    component: <ManagePresidentMessagePage />,
    isAuthenticated: true
  },
  {
    path: '/team-message',
    component: <TeamMessagePage />,
    isAuthenticated: true
  },
  {
    path: '/team-message/:id',
    component: <ManageTeamMessagePage />,
    isAuthenticated: true
  },
  {
    path: '/business-areas',
    component: <BusinessAreasPage />,
    isAuthenticated: true
  },
  {
    path: '/business-areas/new',
    component: <ManageBusinessAreasPage />,
    isAuthenticated: true
  },
  {
    path: '/business-areas/:id',
    component: <ManageBusinessAreasPage />,
    isAuthenticated: true
  },
  {
    path: '/business-sub-areas',
    component: <BusinessSubAreasPage />,
    isAuthenticated: true
  },
  {
    path: '/business-sub-areas/new',
    component: <ManageBusinessSubAreasPage />,
    isAuthenticated: true
  },
  {
    path: '/business-sub-areas/:id',
    component: <ManageBusinessSubAreasPage />,
    isAuthenticated: true
  },
  {
    path: '/portfolio-projects',
    component: <PortfolioProjectsPage />,
    isAuthenticated: true
  },
  {
    path: '/portfolio-projects/new',
    component: <ManagePortfolioProjectsPage />,
    isAuthenticated: true
  },
  {
    path: '/portfolio-projects/:id',
    component: <ManagePortfolioProjectsPage />,
    isAuthenticated: true
  },
  {
    path: '/career-opportunities',
    component: <CareerOpportunitiesPage />,
    isAuthenticated: true
  },
  {
    path: '/career-opportunities/new',
    component: <ManageCareerOpportunitiesPage />,
    isAuthenticated: true
  },
  {
    path: '/career-opportunities/:id',
    component: <ManageCareerOpportunitiesPage />,
    isAuthenticated: true
  },
  {
    path: '/career-opportunities-applications',
    component: <CareerOpportunitiesApplicationsPage />,
    isAuthenticated: true
  },
  {
    path: '/career-opportunities-applications/:id',
    component: <ManageCareerOpportunitiesApplicationsPage />,
    isAuthenticated: true
  },
  {
    path: '/ags-communications',
    component: <AgsCommunicationPage />,
    isAuthenticated: true
  },
  {
    path: '/ags-communications/new',
    component: <ManageAgsCommunicationPage />,
    isAuthenticated: true
  },
  {
    path: '/ags-communications/:id',
    component: <ManageAgsCommunicationPage />,
    isAuthenticated: true
  },
  {
    path: '/companies',
    component: <CompaniesPage />,
    isAuthenticated: true
  },
  {
    path: '/companies/new',
    component: <ManageCompaniesPage />,
    isAuthenticated: true
  },
  {
    path: '/companies/:id',
    component: <ManageCompaniesPage />,
    isAuthenticated: true
  },
  {
    path: '/privacy-policies',
    component: <PrivacyPoliciesPage />,
    isAuthenticated: true
  },
  {
    path: '/privacy-policies/new',
    component: <ManagePrivacyPoliciesPage />,
    isAuthenticated: true
  },
  {
    path: '/privacy-policies/:id',
    component: <ManagePrivacyPoliciesPage />,
    isAuthenticated: true
  },
  {
    path: '/newsletter',
    component: <NewsletterPage />,
    isAuthenticated: true
  },
  {
    path: '/newsletter/:id',
    component: <ManageNewsletterPage />,
    isAuthenticated: true
  },
  {
    path: '/configurations',
    component: <ConfigurationsPage />,
    isAuthenticated: true
  },
  {
    path: '/configurations/:id',
    component: <ManageConfigurationsPage />,
    isAuthenticated: true
  }
];

export default EntityRoutes;
