import { BaseTable, Block, Space, Typography } from '@codepoint-pt/xela';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CareerOpportunityApplication } from '../../models/CareerOpportunityApplication';
import ActiveTranslation from '../../components/languages/ActiveTranslation';
import { AnyObject } from '../../models/Generic';

interface CareerOpportunitiesApplicationsProps {
  data?: CareerOpportunityApplication[];
  loading?: boolean;
}

const CareerOpportunitiesApplicationsTable =
  BaseTable<CareerOpportunityApplication>();

const CareerOpportunitiesApplications = ({
  data,
  loading
}: CareerOpportunitiesApplicationsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = [
    {
      title: t('POSITION'),
      dataIndex: 'career_opportunity_reference',
      render: (row: AnyObject) =>
        row ? <ActiveTranslation value={row?.position} /> : 'N/A'
    },
    {
      title: t('NAME'),
      dataIndex: 'name'
    },
    {
      title: t('EMAIL'),
      dataIndex: 'email'
    },
    {
      title: t('DATE'),
      dataIndex: 'createdAt',
      render: (value: string) =>
        value ? dayjs(value).format('DD/MM/YYYY') : ''
    }
  ];

  return (
    <Block padding="30px" bg="white" borderRadius="16px">
      <Typography variant="subheadline">
        {t('LAST_10_CAREER_OPPORTUNITIES_APPLICATIONS')}
      </Typography>
      <Space h={30} />
      <CareerOpportunitiesApplicationsTable
        columns={columns}
        rows={data || []}
        onRowClick={(row) =>
          navigate(`/career-opportunities-applications/${row._id}`)
        }
        loading={loading}
        translate={t}
      />
    </Block>
  );
};

export default CareerOpportunitiesApplications;
