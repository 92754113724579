import {
  Block,
  Grid,
  LoadingOverlay,
  NumberInput,
  PageHeader,
  SelectInput,
  TextInput,
  toFormData
} from '@codepoint-pt/xela';
import yup from '@codepoint-pt/yup-validations';
import { useEffect, useState } from 'react';
import { Field, withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useFetch from 'use-http';
import headerActions from '../../components/header/HeaderActions';
import TranslatableFields from '../../components/inputs/TranslatableFields';
import FormPrompt from '../../components/prompt/FormPrompt';
import useIntlValidation from '../../hooks/use-validation/use-inlt-validation';
import useSectionValidation from '../../hooks/use-validation/use-section-validation';
import { useValidationSchema } from '../../hooks/use-validation/use-validation-schema';
import {
  CareerOpportunity,
  CareerOpportunityStatusEnum
} from '../../models/CareerOpportunity';
import { BasicForm } from '../../styles/BasicStyles';

const { Form } = withTypes<CareerOpportunity>();

const ManageCareerOpportunitiesPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const intlObject = useIntlValidation();

  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<
    CareerOpportunity | undefined
  >(undefined);

  const { get, post, put, del } = useFetch('/career-opportunities');

  useEffect(() => {
    const init = async () => {
      if (id) {
        const { data, success } = await get(`/${id}`);
        if (success) setInitialValues(data);
      }
      setLoading(false);
    };
    init();
  }, [id]);

  const onSubmit = async (values: CareerOpportunity) => {
    let success = false;
    const payload = toFormData(values);

    if (id) {
      const result = await put(`/${values._id}`, payload);
      success = result.success;
    } else {
      const result = await post(payload);
      success = result.success;
    }

    if (success) navigate('/career-opportunities');
  };

  const onDelete = async () => {
    if (id) {
      setLoading(true);
      const { success } = await del(`/${id}`);
      if (success) navigate('/career-opportunities');
      else setLoading(false);
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={useValidationSchema(
        yup.object({
          position: intlObject({ required: true }),
          description: intlObject({ required: true }),
          status: yup
            .string()
            .oneOf([
              CareerOpportunityStatusEnum.ACTIVE,
              CareerOpportunityStatusEnum.DISABLED,
              CareerOpportunityStatusEnum.DELETED
            ])
            .required(),
          order: yup.number().min(1).required()
        })
      )}
    >
      {({ handleSubmit, submitting, pristine, errors, submitFailed }) => (
        <BasicForm onSubmit={handleSubmit}>
          <LoadingOverlay visible={loading} />
          <FormPrompt
            when={!pristine && !submitting && !loading}
            message={t('PROMPT_MESSAGE')}
          />
          <PageHeader
            navigate={navigate}
            title={t(id ? 'EDIT_CAREER_OPPORTUNITY' : 'ADD_CAREER_OPPORTUNITY')}
            onBack={() => navigate('/career-opportunities')}
            breadcrumbs={[
              { title: t('CAREER_OPPORTUNITY'), href: '/career-opportunities' },
              { title: t(id ? 'EDIT' : 'ADD') }
            ]}
            actions={headerActions({
              submitting,
              pristine,
              backLink: '/career-opportunities',
              onDelete: id ? onDelete : undefined
            })}
          />
          <Block
            borderRadius="16px"
            bg="white"
            padding="50px 15px"
            margin="10px 0"
          >
            <Block margin="0 auto" style={{ maxWidth: '700px' }}>
              <Grid>
                <Grid.Col xs={12}>
                  {/* TODO reference or id ??? */}
                  <TextInput
                    input={{
                      value: initialValues?._id || '',
                      onChange: () => void 0
                    }}
                    label={t('REFERENCE')}
                    disabled
                  />
                </Grid.Col>

                <Grid.Col xs={12}>
                  <TranslatableFields
                    subValidate={(fields) =>
                      useSectionValidation(errors, fields, submitFailed)
                    }
                    fields={[
                      {
                        label: 'POSITION',
                        name: 'position',
                        type: 'text',
                        required: true
                      },
                      {
                        label: 'DESCRIPTION',
                        name: 'description',
                        type: 'textarea',
                        required: true
                      }
                    ]}
                  />
                </Grid.Col>

                <Grid.Col xs={12}>
                  <Field name="status">
                    {(props) => (
                      <SelectInput
                        {...props}
                        label={t('STATUS')}
                        placeholder={t('INPUT_STATUS_PLACEHOLDER')}
                        data={[
                          {
                            _id: CareerOpportunityStatusEnum.ACTIVE,
                            name: t(CareerOpportunityStatusEnum.ACTIVE)
                          },
                          {
                            _id: CareerOpportunityStatusEnum.DISABLED,
                            name: t(CareerOpportunityStatusEnum.DISABLED)
                          },
                          {
                            _id: CareerOpportunityStatusEnum.DELETED,
                            name: t(CareerOpportunityStatusEnum.DELETED)
                          }
                        ]}
                        required
                      />
                    )}
                  </Field>
                </Grid.Col>

                <Grid.Col xs={12}>
                  <Field name="order">
                    {(props) => (
                      <NumberInput
                        {...props}
                        label={t('ORDER')}
                        placeholder={t('INPUT_ORDER_PLACEHOLDER')}
                        min={1}
                        required
                      />
                    )}
                  </Field>
                </Grid.Col>
              </Grid>
            </Block>
          </Block>
        </BasicForm>
      )}
    </Form>
  );
};

export default ManageCareerOpportunitiesPage;
