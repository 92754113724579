import { Icon } from '@codepoint-pt/xela';

export type MenuType = 'link' | 'divider' | 'dropdown';

export interface MenuOptionProps {
  type: MenuType;
  name?: string;
  url?: string;
  icon?: React.ReactElement;
  options?: MenuOptionProps[];
  superuser?: boolean;
}

export const MenuOptions: MenuOptionProps[] = [
  {
    type: 'link',
    name: 'DASHBOARD',
    url: '/',
    icon: <Icon icon="charts_pieChart" size={16} />
  },
  {
    type: 'link',
    name: 'ADMINISTRATORS',
    url: '/admins',
    icon: <Icon icon="essentials_key" size={16} />
  },
  {
    type: 'link',
    name: 'CONTACT_REQUESTS',
    url: '/contact-requests',
    icon: <Icon icon="essentials_phonecallReceive" size={16} />
  },
  {
    type: 'link',
    name: 'NEWSLETTER',
    url: '/newsletter',
    icon: <Icon icon="authoring_floatLeft" size={16} />
  },
  {
    type: 'dropdown',
    name: 'HUMAN_RESOURCES',
    icon: <Icon icon="essentials_userCircle" size={16} />,
    options: [
      {
        type: 'link',
        name: 'CAREER_OPPORTUNITIES',
        url: '/career-opportunities',
        icon: <Icon icon="essentials_contact" size={16} />
      },
      {
        type: 'link',
        name: 'CAREER_APPLICATIONS',
        url: '/career-opportunities-applications',
        icon: <Icon icon="essentials_userPlus" size={16} />
      }
    ]
  },
  {
    type: 'dropdown',
    name: 'CONTENT_MANAGEMENT',
    icon: <Icon icon="interface_gridLayout" size={16} />,
    options: [
      {
        type: 'link',
        name: 'BO_VISION_MISSION_VALUES',
        url: '/banner-options',
        icon: <Icon icon="authoring_imageAlt" size={16} />
      },
      {
        type: 'link',
        name: 'PRESIDENT_MESSAGE',
        url: '/president-message',
        icon: <Icon icon="tasks_messageMinus" size={16} />
      },
      {
        type: 'link',
        name: 'TEAM_MESSAGE',
        url: '/team-message',
        icon: <Icon icon="essentials_messageText" size={16} />
      },
      {
        type: 'link',
        name: 'PORTFOLIO_PROJECTS',
        url: '/portfolio-projects',
        icon: <Icon icon="files_folder" size={16} />
      },
      {
        type: 'link',
        name: 'COMPANIES',
        url: '/companies',
        icon: <Icon icon="estate_building" size={16} />
      },
      {
        type: 'link',
        name: 'PRIVACY_POLICY',
        url: '/privacy-policies',
        icon: <Icon icon="files_textDocumentAlt" size={16} />
      }
    ]
  },
  {
    type: 'dropdown',
    name: 'BUSINESS_AREAS',
    icon: <Icon icon="estate_shop" size={16} />,
    options: [
      {
        type: 'link',
        name: 'BUSINESS_AREAS',
        url: '/business-areas',
        icon: <Icon icon="commerce_cart" size={16} />
      },
      {
        type: 'link',
        name: 'BUSINESS_SUBAREAS',
        url: '/business-sub-areas',
        icon: <Icon icon="commerce_cartMinus" size={16} />
      }
    ]
  },
  {
    type: 'link',
    name: 'AGS_COMMUNICATION',
    url: '/ags-communications',
    icon: <Icon icon="files_contract" size={16} />
  },
  {
    type: 'link',
    name: 'CONFIGURATIONS',
    url: '/configurations',
    icon: <Icon icon="essentials_cog" size={16} />
  },
  {
    type: 'link',
    name: 'METATAGS',
    url: '/metatags',
    icon: <Icon icon="commerce_tag" />
  },
  {
    type: 'link',
    name: 'TRANSLATIONS',
    url: '/translations',
    icon: <Icon icon="essentials_globe" size={16} />,
    superuser: true
  },
  {
    type: 'link',
    name: 'EMAIL_TEMPLATES',
    url: '/email-templates',
    icon: <Icon icon="essentials_envelope" size={16} />,
    superuser: true
  },
  {
    type: 'link',
    name: 'LOGS',
    url: '/logs',
    icon: <Icon icon="technology_microSdCard" size={16} />,
    superuser: true
  }
];
