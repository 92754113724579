import {
  Block,
  Grid,
  LoadingOverlay,
  PageHeader,
  TextInput,
  toFormData
} from '@codepoint-pt/xela';
import yup from '@codepoint-pt/yup-validations';
import { useEffect, useState } from 'react';
import { Field, withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useFetch from 'use-http';
import headerActions from '../../components/header/HeaderActions';
import FormPrompt from '../../components/prompt/FormPrompt';
import { useValidationSchema } from '../../hooks/use-validation/use-validation-schema';
import { Configuration } from '../../models/Configuration';
import { BasicForm } from '../../styles/BasicStyles';

const { Form } = withTypes<Configuration>();

const ManageConfigurationsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({});

  const { get, post, put } = useFetch('/configurations');

  useEffect(() => {
    const init = async () => {
      if (id) {
        const { data, success } = await get(`/${id}`);
        if (success) setInitialValues(data);
      }
      setLoading(false);
    };
    init();
  }, [id]);

  const onSubmit = async (values: Configuration) => {
    let success = false;
    const payload = toFormData(values);

    if (id) {
      const result = await put(`/${values._id}`, payload);
      success = result.success;
    } else {
      const result = await post(payload);
      success = result.success;
    }

    if (success) navigate('/configurations');
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={useValidationSchema(
        yup.object({
          name: yup.string().required(),
          value: yup.string().required()
        })
      )}
    >
      {({ handleSubmit, submitting, pristine }) => (
        <BasicForm onSubmit={handleSubmit}>
          <LoadingOverlay visible={loading} />
          <FormPrompt
            when={!pristine && !submitting && !loading}
            message={t('PROMPT_MESSAGE')}
          />
          <PageHeader
            navigate={navigate}
            title={t(id ? 'EDIT_CONFIGURATION' : 'ADD_CONFIGURATION')}
            onBack={() => navigate('/configurations')}
            breadcrumbs={[
              { title: t('CONFIGURATIONS'), href: '/configurations' },
              { title: t(id ? 'EDIT' : 'ADD') }
            ]}
            actions={headerActions({
              submitting,
              pristine,
              backLink: '/configurations'
            })}
          />
          <Block
            borderRadius="16px"
            bg="white"
            padding="50px 15px"
            margin="10px 0"
          >
            <Block margin="0 auto" style={{ maxWidth: '700px' }}>
              <Grid>
                <Grid.Col xs={12}>
                  <Field name="name">
                    {(props) => (
                      <TextInput
                        {...props}
                        label={t('NAME')}
                        placeholder={t('INPUT_NAME_PLACEHOLDER')}
                        withAsterisk
                        disabled
                      />
                    )}
                  </Field>
                </Grid.Col>

                <Grid.Col xs={12}>
                  <Field name="value">
                    {(props) => (
                      <TextInput
                        {...props}
                        label={t('VALUE')}
                        placeholder={t('INPUT_VALUE_PLACEHOLDER')}
                        withAsterisk
                      />
                    )}
                  </Field>
                </Grid.Col>
              </Grid>
            </Block>
          </Block>
        </BasicForm>
      )}
    </Form>
  );
};

export default ManageConfigurationsPage;
