import {
  AspectRatios,
  Block,
  Grid,
  ImageInput,
  LoadingOverlay,
  PageHeader,
  TextInput,
  toFormData
} from '@codepoint-pt/xela';
import yup from '@codepoint-pt/yup-validations';
import { useEffect, useState } from 'react';
import { Field, withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useFetch from 'use-http';
import headerActions from '../../components/header/HeaderActions';
import TranslatableFields from '../../components/inputs/TranslatableFields';
import FormPrompt from '../../components/prompt/FormPrompt';
import useIntlValidation from '../../hooks/use-validation/use-inlt-validation';
import useSectionValidation from '../../hooks/use-validation/use-section-validation';
import { useValidationSchema } from '../../hooks/use-validation/use-validation-schema';
import { Company } from '../../models/Company';
import { BasicForm } from '../../styles/BasicStyles';

const { Form } = withTypes<Company>();

const ManageCompaniesPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const intlObject = useIntlValidation();

  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({});

  const { get, post, put } = useFetch('/companies');

  useEffect(() => {
    const init = async () => {
      if (id) {
        const { data, success } = await get(`/${id}`);

        if (success) setInitialValues(data);
      }
      setLoading(false);
    };
    init();
  }, [id]);

  const onSubmit = async (values: Company) => {
    let success = false;
    const payload = toFormData(values);

    if (id) {
      const result = await put(`/${values._id}`, payload);
      success = result.success;
    } else {
      const result = await post(payload);
      success = result.success;
    }

    if (success) navigate('/companies');
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={useValidationSchema(
        yup.object({
          title: intlObject({ required: true }),
          video: intlObject({ required: false }).required()
        })
      )}
    >
      {({ handleSubmit, submitting, pristine, errors, submitFailed }) => (
        <BasicForm onSubmit={handleSubmit}>
          <LoadingOverlay visible={loading} />
          <FormPrompt
            when={!pristine && !submitting && !loading}
            message={t('PROMPT_MESSAGE')}
          />
          <PageHeader
            navigate={navigate}
            title={t(id ? 'EDIT_COMPANY' : 'ADD_COMPANY')}
            onBack={() => navigate('/companies')}
            breadcrumbs={[
              { title: t('COMPANIES'), href: '/companies' },
              { title: t(id ? 'EDIT' : 'ADD') }
            ]}
            actions={headerActions({
              submitting,
              pristine,
              backLink: '/companies'
            })}
          />
          <Block
            borderRadius="16px"
            bg="white"
            padding="50px 15px"
            margin="10px 0"
          >
            <Block margin="0 auto" style={{ maxWidth: '700px' }}>
              <Grid>
                <Grid.Col xs={12}>
                  <Field name="tag">
                    {(props) => (
                      <TextInput {...props} label={t('TAG')} disabled />
                    )}
                  </Field>
                </Grid.Col>

                <Grid.Col xs={6} offset={3}>
                  <Field name="image">
                    {(props) => (
                      <ImageInput
                        {...props}
                        ratio={AspectRatios['3:4']}
                        label={t('IMAGE')}
                      />
                    )}
                  </Field>
                </Grid.Col>

                <Grid.Col xs={12}>
                  <TranslatableFields
                    subValidate={(fields) =>
                      useSectionValidation(errors, fields, submitFailed)
                    }
                    fields={[
                      {
                        label: 'TITLE',
                        name: 'title',
                        type: 'text',
                        required: true
                      },
                      {
                        label: 'HIGHLIGHT',
                        name: 'highlight',
                        type: 'textarea',
                        required: true
                      }
                    ]}
                  />
                </Grid.Col>

                <Grid.Col xs={12}>
                  <Field name="url">
                    {(props) => <TextInput {...props} label={t('URL')} />}
                  </Field>
                </Grid.Col>
              </Grid>
            </Block>
          </Block>
        </BasicForm>
      )}
    </Form>
  );
};

export default ManageCompaniesPage;
