import {
  Block,
  Grid,
  LoadingOverlay,
  NumberInput,
  PageHeader,
  TextInput,
  toFormData
} from '@codepoint-pt/xela';
import yup from '@codepoint-pt/yup-validations';
import { useEffect, useState } from 'react';
import { Field, withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import useFetch from 'use-http';
import headerActions from '../../components/header/HeaderActions';
import TranslatableFields from '../../components/inputs/TranslatableFields';
import FormPrompt from '../../components/prompt/FormPrompt';
import useIntlValidation from '../../hooks/use-validation/use-inlt-validation';
import useSectionValidation from '../../hooks/use-validation/use-section-validation';
import { useValidationSchema } from '../../hooks/use-validation/use-validation-schema';
import { DynamicPage } from '../../models/DynamicPage';
import { BasicForm } from '../../styles/BasicStyles';

const { Form } = withTypes<DynamicPage>();

const ManageDynamicPagesPage = () => {
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const intlObject = useIntlValidation();

  const { get, post, put } = useFetch('/dynamic-pages');

  useEffect(() => {
    const init = async () => {
      if (id) {
        const { data, success } = await get(`/${id}`);
        if (success) setInitialValues(data);
      }
      setLoading(false);
    };
    init();
  }, [id]);

  const onSubmit = async (values: DynamicPage) => {
    let success = false;
    const payload = toFormData(values);

    if (id) {
      const result = await put(`/${values._id}`, payload);
      success = result.success;
    } else {
      const result = await post(payload);
      success = result.success;
    }

    if (success) navigate('/dynamic-pages');
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={useValidationSchema(
        yup.object({
          title: intlObject({ required: true }),
          content: intlObject({ required: true, html: true })
        })
      )}
    >
      {({ handleSubmit, submitting, pristine, errors, submitFailed }) => (
        <BasicForm onSubmit={handleSubmit}>
          <LoadingOverlay visible={loading} />
          <FormPrompt
            when={!pristine && !submitting && !loading}
            message={t('PROMPT_MESSAGE')}
          />
          <PageHeader
            navigate={navigate}
            title={t(id ? 'EDIT_DYNAMIC_PAGE' : 'ADD_DYNAMIC_PAGE')}
            onBack={() => navigate('/dynamic-pages')}
            breadcrumbs={[
              { title: t('DYNAMIC_PAGES'), href: '/dynamic-pages' },
              { title: t(id ? 'EDIT' : 'ADD') }
            ]}
            actions={headerActions({
              submitting,
              pristine,
              backLink: '/dynamic-pages'
            })}
          />
          <Block borderRadius="16px" bg="white" padding="15px" margin="10px 0">
            <Grid gutter="xl">
              <Grid.Col xs={12}>
                <Field name="tag">
                  {(props) => <TextInput {...props} label={t('TAG')} />}
                </Field>
              </Grid.Col>
              <Grid.Col xs={12}>
                <TranslatableFields
                  subValidate={(fields) =>
                    useSectionValidation(errors, fields, submitFailed)
                  }
                  fields={[
                    {
                      label: 'TITLE',
                      name: 'title',
                      type: 'text'
                    },
                    {
                      label: 'CONTENT',
                      name: 'content',
                      type: 'richtext'
                    }
                  ]}
                />
              </Grid.Col>

              <Grid.Col xs={12}>
                <Field name="order">
                  {(props) => (
                    <NumberInput
                      {...props}
                      label={t('ORDER')}
                      placeholder={t('INPUT_ORDER_PLACEHOLDER')}
                      min={1}
                      required
                    />
                  )}
                </Field>
              </Grid.Col>
            </Grid>
          </Block>
        </BasicForm>
      )}
    </Form>
  );
};

export default ManageDynamicPagesPage;
