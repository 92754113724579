import { Block, Grid, Space } from '@codepoint-pt/xela';
import { useEffect, useState } from 'react';
import { useFetch } from 'use-http';
import { CareerOpportunityApplication } from '../../models/CareerOpportunityApplication';
import { ContactRequest } from '../../models/ContactRequest';
import { NewsletterSubscriber } from '../../models/NewsletterSubscriber';
import CareerOpportunitiesApplications from './CareerOpportunitiesApplications';
import ContactRequests from './ContactRequests';
import NewsletterSubscribers from './NewsletterSubscribers';

export interface Dashboard {
  careerOpportunitiesApplications: CareerOpportunityApplication[];
  contactRequests: ContactRequest[];
  newsletterSubscribers: NewsletterSubscriber[];
}

const DashboardPage = () => {
  const [data, setData] = useState<Dashboard>();
  const [loading, setLoading] = useState<boolean>(false);
  const { get } = useFetch('/info/statistics');

  useEffect(() => {
    const init = async () => {
      setLoading(true);

      const { data, success } = await get();

      if (success) setData(data);

      setLoading(false);
    };
    init();
  }, []);

  return (
    <Block>
      <Space h={20} />
      <Grid gutter="lg">
        <Grid.Col xs={12}>
          <CareerOpportunitiesApplications
            data={data?.careerOpportunitiesApplications}
            loading={loading}
          />
        </Grid.Col>
        <Grid.Col xs={12}>
          <ContactRequests data={data?.contactRequests} loading={loading} />
        </Grid.Col>
        <Grid.Col xs={12}>
          <NewsletterSubscribers
            data={data?.newsletterSubscribers}
            loading={loading}
          />
        </Grid.Col>
      </Grid>
    </Block>
  );
};

export default DashboardPage;
