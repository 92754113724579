import {
  LoadingOverlay,
  PageHeader,
  Block,
  Grid,
  TextInput,
  toFormData,
  ImageInput,
  AspectRatios
} from '@codepoint-pt/xela';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { Field, withTypes } from 'react-final-form';
import { Metatags } from '../../models/Metatags';
import { BasicForm } from '../../styles/BasicStyles';
import { useState, useEffect } from 'react';
import useFetch from 'use-http';
import TranslatableFields from '../../components/inputs/TranslatableFields';
import FormPrompt from '../../components/prompt/FormPrompt';
import headerActions from '../../components/header/HeaderActions';

const { Form } = withTypes<Metatags>();

const ManageAdministratorPage = () => {
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const { get, put } = useFetch('/metatags');

  useEffect(() => {
    const init = async () => {
      if (id) {
        const { data, success } = await get(`/${id}`);
        if (success) setInitialValues(data);
      }
      setLoading(false);
    };
    init();
  }, [id]);

  const onSubmit = async (values: Metatags) => {
    if (id) {
      const payload = toFormData(values);
      const { success } = await put(`/${values._id}`, payload);
      if (success) navigate('/metatags');
    }
  };

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, submitting, pristine }) => (
        <BasicForm onSubmit={handleSubmit}>
          <LoadingOverlay visible={loading} />
          <FormPrompt
            when={!pristine && !submitting}
            message={t('PROMPT_MESSAGE')}
          />
          <PageHeader
            navigate={navigate}
            title={t('EDIT_METATAGS')}
            onBack={() => navigate('/metatags')}
            breadcrumbs={[
              { title: t('METATAGS'), href: '/metatags' },
              {
                title: t('EDIT')
              }
            ]}
            actions={headerActions({
              submitting,
              pristine,
              backLink: '/metatags'
            })}
          />
          <Block borderRadius="16px" bg="white" padding="15px" margin="10px 0">
            <Grid>
              <Grid.Col xs={12}>
                <Field name="name">
                  {(props) => (
                    <TextInput {...props} label={t('NAME')} disabled={true} />
                  )}
                </Field>
              </Grid.Col>
              <Grid.Col xs={12}>
                <Field name="tag">
                  {(props) => (
                    <TextInput {...props} label={t('TAG')} disabled={true} />
                  )}
                </Field>
              </Grid.Col>
            </Grid>
            <Grid style={{ marginTop: '30px' }}>
              <Grid.Col xs={6} offset={3}>
                <Field name="image">
                  {(props) => (
                    <ImageInput
                      {...props}
                      ratio={AspectRatios['16:9']}
                      label={t('IMAGE')}
                    />
                  )}
                </Field>
              </Grid.Col>
              <Grid.Col xs={12}>
                <TranslatableFields
                  subValidate={() => false}
                  fields={[
                    { label: 'METADATA_TITLE', name: 'title', type: 'text' },
                    {
                      label: 'METADATA_DESCRIPTION',
                      name: 'description',
                      type: 'textarea'
                    },
                    { label: 'KEYWORDS', name: 'keywords', type: 'textarea' }
                  ]}
                />
              </Grid.Col>
            </Grid>
          </Block>
        </BasicForm>
      )}
    </Form>
  );
};

export default ManageAdministratorPage;
