import {
  CreatePreviewFile,
  Icon,
  InputWrapper,
  XelaColor
} from '@codepoint-pt/xela';
import { Dropzone } from '@mantine/dropzone';
import { FieldRenderProps } from 'react-final-form';
import styled from 'styled-components';
import { showError } from '../../hooks/show-notification/show-notification';
import { AnyObject, GenericInput } from '../../models/Generic';
import { useTranslation } from 'react-i18next';

const InputSection = styled.div<{ hasError?: boolean }>`
  margin-top: 5px;
  width: 100%;
  height: 36px;
  line-height: 34px;
  box-sizing: border-box;
  font-size: 14px;
  width: 100%;
  color: #000;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-self: center;
  text-align: left;
  min-height: 36px;
  padding-left: 0;
  padding-right: 0;
  border-radius: 16px;
  border: 1px solid
    ${({ hasError }) => (hasError ? XelaColor.Red5 : XelaColor.Gray10)};
  -webkit-transition: border-color 100ms ease;
  transition: border-color 100ms ease;
  position: relative;
`;

const IconContainer = styled.div`
  display: inline-block;
  width: 50px;
`;

const PlaceholderContainer = styled.div<{ padding?: boolean }>`
  display: inline-block;
  padding: 0 10px;
  padding-left: 10px;
  padding-left: ${({ padding }) => (padding ? 40 : 10)}px;
`;

const OpenButton = styled.div`
  position: absolute;
  cursor: pointer;
  bottom: 10px;
  left: 60px;
`;

export interface FileInputProps
  extends Omit<FieldRenderProps<AnyObject>, 'input'> {
  afterChange?: (value: AnyObject) => void;
  input: GenericInput<AnyObject>;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  unavailable?: boolean;
  errorTitle?: string;
  errorMessage?: string;
  icon?: JSX.Element;
}

export const FileInput: React.FC<FileInputProps> = ({
  input,
  meta,
  label,
  disabled,
  placeholder = 'Drag or drop file',
  unavailable,
  errorTitle = 'Invalid file',
  errorMessage = 'The file you tried to upload is invalid',
  icon
}) => {
  const { t } = useTranslation();

  const hasError = meta?.invalid && meta?.submitFailed;

  const handleAccept = (files: File[]) => {
    if (files[0]) {
      input.onChange(CreatePreviewFile(files[0]));
    }
  };

  const handleReject = () => {
    showError({
      title: errorTitle,
      message: errorMessage,
      color: 'red'
    });
  };

  const openFile = () => {
    window.open(input?.value?.url, '_blank', 'noopener,noreferrer');
  };

  const renderDropzone = input?.value ? (
    <PlaceholderContainer padding={input?.value?.url}>
      {input.value?.blob?.name || input.value?.name}
    </PlaceholderContainer>
  ) : (
    <PlaceholderContainer>
      {unavailable ? t('NO_FILE_ATTACHED') : placeholder}
    </PlaceholderContainer>
  );

  const renderOpen = input?.value?.url && (
    <OpenButton onClick={openFile}>
      <Icon icon="arrows_link" size={15} />
    </OpenButton>
  );

  return (
    <InputWrapper label={label}>
      <InputSection
        hasError={hasError}
        onClick={disabled ? () => openFile() : () => void 0}
      >
        <IconContainer>{icon}</IconContainer>
        <Dropzone
          disabled={disabled}
          onDrop={handleAccept}
          onReject={handleReject}
          accept={['application/pdf']}
          multiple={false}
          styles={{
            root: {
              border: 'none',
              padding: 0,
              display: 'inline-block',
              width: 'calc(100% - 50px)',
              borderBottomLeftRadius: 0,
              borderTopLeftRadius: 0
            }
          }}
        >
          {renderDropzone}
        </Dropzone>
        {renderOpen}
      </InputSection>
    </InputWrapper>
  );
};

export default FileInput;
