import { DefaultListPage, Grid, SearchInput } from '@codepoint-pt/xela';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useFetch from 'use-http';
import { CareerOpportunity } from '../../models/CareerOpportunity';
import { CareerOpportunityApplication } from '../../models/CareerOpportunityApplication';

const CareerOpportunitiesApplicationsListPage =
  DefaultListPage<CareerOpportunityApplication>();

const CareerOpportunitiesApplicationsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = [
    {
      title: t('REFERENCE'),
      dataIndex: 'career_opportunity_reference',
      sortable: true,
      render: (value?: CareerOpportunity) => value?._id || ''
    },
    {
      title: t('NAME'),
      dataIndex: 'name',
      sortable: true
    },
    {
      title: t('EMAIL'),
      dataIndex: 'email',
      sortable: true
    },
    {
      title: t('DATE'),
      dataIndex: 'createdAt',
      sortable: true,
      render: (value: string) =>
        value ? dayjs(value).format('DD/MM/YYYY') : ''
    }
  ];

  const { get } = useFetch('/career-opportunities-applications');

  return (
    <CareerOpportunitiesApplicationsListPage
      translate={t}
      request={{
        get,
        searchFields: ['reference', 'name', 'email']
      }}
      storage="CAREER_APPLICATIONS_FILTERS"
      header={{
        navigate,
        title: t('CAREER_APPLICATIONS'),
        breadcrumbs: [
          {
            title: t('CAREER_APPLICATIONS'),
            href: '/career-opportunities-applications'
          }
        ]
      }}
      filters={({ filters, handleChange }) => (
        <Grid gutter={24}>
          <Grid.Col xs={4}>
            <SearchInput
              input={{
                value: filters.search,
                onChange: (v: string | null) => handleChange('search', v, 1000)
              }}
              onSearch={(v) => handleChange('search', v)}
            />
          </Grid.Col>
        </Grid>
      )}
      table={{
        columns,
        onRowClick: (row) =>
          navigate(`/career-opportunities-applications/${row._id}`)
      }}
    />
  );
};

export default CareerOpportunitiesApplicationsPage;
