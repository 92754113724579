import { AnyObject } from './Generic';

export interface AgsCommunication {
  readonly _id: string;
  title: AnyObject;
  file: AnyObject;
  type: FileDocumentTypeLEnum;
  order: number;
}

export enum FileDocumentTypeLEnum {
  CORPORATE_GOVERNANCE = 'CORPORATE_GOVERNANCE',
  POLICIES = 'POLICIES',
  COMMUNICATION = 'COMMUNICATION'
}
