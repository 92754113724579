import { DefaultListPage, Grid, Icon, SearchInput } from '@codepoint-pt/xela';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useFetch from 'use-http';
import ActiveTranslation from '../../components/languages/ActiveTranslation';
import { BusinessArea } from '../../models/BusinessArea';

const BusinessSubAreasListPage = DefaultListPage<BusinessArea>();

const BusinessSubAreasPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = [
    {
      title: t('TITLE'),
      dataIndex: 'title',
      sortable: true,
      render: (value: string) => <ActiveTranslation value={value} />
    },
    {
      title: t('ORDER'),
      dataIndex: 'order',
      sortable: true
    }
  ];

  const { get } = useFetch('/business-sub-areas');

  return (
    <BusinessSubAreasListPage
      translate={t}
      request={{
        get,
        searchFields: ['title']
      }}
      storage="BUSINESS_SUBAREAS_FILTERS"
      header={{
        navigate,
        title: t('BUSINESS_SUBAREAS'),
        // TODO remove action
        actions: [
          {
            name: t('ADD_NEW'),
            icon: <Icon icon="basics_add" color="white" />,
            onClick: () => navigate('/business-sub-areas/new')
          }
        ],
        breadcrumbs: [
          { title: t('BUSINESS_SUBAREAS'), href: '/business-sub-areas' }
        ]
      }}
      filters={({ filters, handleChange }) => (
        <Grid gutter={24}>
          <Grid.Col xs={4}>
            <SearchInput
              input={{
                value: filters.search,
                onChange: (v: string | null) => handleChange('search', v, 1000)
              }}
              onSearch={(v) => handleChange('search', v)}
            />
          </Grid.Col>
        </Grid>
      )}
      table={{
        columns,
        onRowClick: (row) => navigate(`/business-sub-areas/${row._id}`)
      }}
    />
  );
};

export default BusinessSubAreasPage;
